



















































































































































































































































































































.examinationHallDataTs {
  color: red;
}

.bgc-bv:disabled,
.bgc-bv:disabled:hover {
  //color: #C0C4CC!important;
  cursor: not-allowed;
  background-image: none;
  //background-color: #FFF!important;
  //border-color: #EBEEF5!important;
  color: #8cc5ff !important;
  background-color: #ecf5ff !important;
  border-color: #d9ecff !important;
}

.changeSeatNumber {
  width: 100%;
  /deep/ input {
    text-align: left;
  }
}