.examinationHallDataTs {
  color: red;
}
.bgc-bv:disabled,
.bgc-bv:disabled:hover {
  cursor: not-allowed;
  background-image: none;
  color: #8cc5ff !important;
  background-color: #ecf5ff !important;
  border-color: #d9ecff !important;
}
.changeSeatNumber {
  width: 100%;
}
.changeSeatNumber /deep/ input {
  text-align: left;
}
